import styled from "styled-components"

import { colors } from "src/ui/colors"
import { MDetails } from "src/ui/ExpandableSection/MDetails"
import Important from "src/ui/icons/important-filled-default.svg"
import { spacing } from "src/ui/spacing"

export function ErrorBanner({
  title,
  description,
  action,
}: {
  title: React.ReactNode
  description?: React.ReactNode
  action?: React.ReactNode
}) {
  return (
    <MDetails
      icon={
        <Important
          width="24px"
          color={colors.semanticEmergency}
          fill={colors.primaryBackground}
        />
      }
      open={true}
      onChange={() => {}}
      variant="card"
      summaryProps={{
        bgColor: colors.semanticEmergency,
        fgColor: colors.primaryBackground,
        cardPaddingOverride: `${spacing.M} ${spacing.L}`,
      }}
      title={title}
    >
      <BannerContents>
        {description && <div>{description}</div>}

        {action && <div>{action}</div>}
      </BannerContents>
    </MDetails>
  )
}

const BannerContents = styled.div`
  display: grid;
  grid-gap: ${spacing.M};
  padding: ${spacing.M} ${spacing.L};
`
